import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Subscribe extends React.Component {
  state = {
    email: "",
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Subscribe" keywords={[`blog`]} />

        <p>
          <strong>
            Subscribe via email with{" "}
            <a href="https://mariechatfield.us20.list-manage.com/subscribe?u=f804212e4e12d6c8c599d9edc&id=d8751c76f9">
              Mailchimp
            </a>
            .
          </strong>
        </p>

        <p>
          <link
            href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
            rel="stylesheet"
            type="text/css"
          />

          <div
            id="mc_embed_signup"
            style={{
              background: "#fff",
              clear: "left",
              font: "14px Helvetica,Arial,sans-serif",
              width: "100%",
            }}
          >
            <form
              action="https://mariechatfield.us20.list-manage.com/subscribe/post?u=f804212e4e12d6c8c599d9edc&amp;id=d8751c76f9"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              rel="noopener noreferrer"
              novalidate
            >
              <div id="mc_embed_signup_scroll">
                <input
                  type="email"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                  name="EMAIL"
                  class="email"
                  style={{ marginRight: "8px" }}
                  id="mce-EMAIL"
                  placeholder="email address"
                  required
                />
                {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_f804212e4e12d6c8c599d9edc_d8751c76f9"
                    tabindex="-1"
                    value=""
                  />
                </div>
                <div class="clear">
                  <input
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="button"
                  />
                </div>
              </div>
            </form>
          </div>
        </p>

        <p>
          <strong>
            Subscribe via the <a href="/rss.xml">RSS feed</a>.
          </strong>
        </p>

        <p>
          Haven't used an RSS feed before? You can use a feed reader like{" "}
          <a
            href="https://feedly.com/i/welcome"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feedly
          </a>{" "}
          or{" "}
          <a
            href="https://feeder.co/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feeder
          </a>{" "}
          to subscribe to blogs and read them all in one place. Or you can use a
          tool like{" "}
          <a
            href="https://ifttt.com/feed"
            target="_blank"
            rel="noopener noreferrer"
          >
            IFTTT
          </a>{" "}
          or{" "}
          <a
            href="https://zapier.com/apps/rss/integrations"
            target="_blank"
            rel="noopener noreferrer"
          >
            Zapier
          </a>{" "}
          to automatically email yourself when a new post is published!
        </p>
      </Layout>
    )
  }
}

export default Subscribe

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
